import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { assertString } from 'helpers/utils/checkType';

export enum QueryKeys {
  DestinationPath = 'dp',
  UserId = 'user_id',
  ClickId = 'click_id',
  MailsFiltersTab = 'mails_tab',
  XUlid = 'x_ulid',
  Tab = 'tab',
  Search = 'search',
  IsOnline = 'is_online',
}

export const useSystemSearchQueries = () => {
  const { search } = useLocation();

  const {
    [QueryKeys.DestinationPath]: destinationPath,
    [QueryKeys.UserId]: userId,
    [QueryKeys.ClickId]: clickId,
    [QueryKeys.MailsFiltersTab]: mailsFiltersTab,
    [QueryKeys.Tab]: tab,
    [QueryKeys.XUlid]: xUlid,
  } = useMemo(() => queryString.parse(search), [search]);

  const destinationPathQuery = useMemo(() => {
    return {
      key: QueryKeys.DestinationPath,
      value: assertString(destinationPath) ? destinationPath : null,
    };
  }, [destinationPath]);

  const userIdQuery = useMemo(() => {
    return {
      key: QueryKeys.UserId,
      value: assertString(userId) ? userId : null,
    };
  }, [userId]);

  const clickIdQuery = useMemo(() => {
    return {
      key: QueryKeys.ClickId,
      value: assertString(clickId) ? clickId : null,
    };
  }, [clickId]);

  const mailsFiltersTabQuery = useMemo(() => {
    return {
      key: QueryKeys.MailsFiltersTab,
      value: assertString(mailsFiltersTab) ? mailsFiltersTab : null,
    };
  }, [mailsFiltersTab]);

  const icebreakerTabQuery = useMemo(() => {
    return {
      key: QueryKeys.Tab,
      value: assertString(tab) ? tab : null,
    };
  }, [tab]);

  const xUlidQuery = useMemo(() => {
    return {
      key: QueryKeys.XUlid,
      value: assertString(xUlid) ? xUlid : null,
    };
  }, [xUlid]);

  return {
    destinationPathQuery,
    userIdQuery,
    clickIdQuery,
    mailsFiltersTabQuery,
    icebreakerTabQuery,
    xUlidQuery,
  };
};
